import { graphql } from "gatsby";
import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import Slices from "../components/slices/silces";

export const query = graphql`
	query ProductQuery($uid: String) {
		prismic {
			allPages(uid: $uid) {
				edges {
					node {
						body {
							... on PRISMIC_PageBodyCollapsible_list {
								type
								label
								fields {
									collapse_title
									collapse_details
								}
							}
							... on PRISMIC_PageBodyInfo_card_group {
								type
								label
								fields {
									card_details
									card_title
									imageIcon
								}
							}
							... on PRISMIC_PageBodyHorizontal_line {
								type
								label
								primary {
									optional_line_text
								}
							}
							... on PRISMIC_PageBodyText {
								type
								label
								primary {
									text
								}
							}
							... on PRISMIC_PageBodyLogo_group {
								type
								label
								fields {
									image_url
								}
							}
							... on PRISMIC_PageBodyFull_image {
								type
								label
								primary {
									full_image
								}
							}
							... on PRISMIC_PageBodyEmployee_profile_list {
								type
								label
								fields {
									employee {
										_linkType
										... on PRISMIC_Employee {
											employee_name
											employee_title
											email {
												... on PRISMIC__ExternalLink {
													_linkType
													url
												}
											}
											linked_in_url {
												... on PRISMIC__ExternalLink {
													_linkType
													url
												}
											}
											short_bio
											employee_photo
											_meta {
												uid
												type
											}
										}
									}
								}
							}
							... on PRISMIC_PageBodySkinny_image {
								type
								label
								primary {
									full_image
									label
								}
							}
							... on PRISMIC_PageBodyContact_form {
								type
								label
								primary {
									contact_details
									contact_details_label
									contact_form_label
								}
							}
						}
						_linkType
						title
						_meta {
							uid
							type
							id
							lang
						}
					}
				}
			}
		}
	}
`;

const Page = props => {
	const doc = props.data.prismic.allPages.edges.slice(0, 1).pop();
	if (!doc) {
		return <div>404</div>;
	}
	return (
		<Layout>
			<SEO title={doc.node.title[0].text} />
			<Slices slices={doc.node.body} />
		</Layout>
	);
};

export default Page;
