import React, { ReactElement } from "react";

import PrismicHref from "../../prismicHref";
import sliceClasses from "../slices.module.css";
import classes from "./logoGroup.module.css";

interface Props {
	slice: any;
}

const LogoGroup = (props: Props): ReactElement => {
	return (
		<div className={sliceClasses.container}>
			<div className={classes.container}>
				{props.slice.fields.map((field, index) => {
					return (
						<div className={classes.img} key={index}>
							<img
								src={field.image_url.url}
								key={index}
								alt={field.image_url.alt}
							/>
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default LogoGroup;
