import { RichText } from "prismic-reactjs";
import React, { ReactElement } from "react";

import SkinnyHeader from "../../skinnyHeader";
import classes from "./skinnyImage.module.css";

interface Props {
	slice: any;
}

const SkinnyImage = (props: Props): ReactElement => {
	const content = props.slice.primary;
	return (
		<>
			<SkinnyHeader
				imageUrl={content?.full_image?.url || ""}
				label={RichText.asText(content?.label) || ""}
			/>
		</>
	);
};

export default SkinnyImage;
