import clsx from "clsx";
import { RichText } from "prismic-reactjs";
import React, { Fragment, ReactElement, useState } from "react";
import AnimateHeight from "react-animate-height";

import classes from "./column.module.css";

interface Field {
	collapse_title: any;
	collapse_details: any;
}

interface Props {
	fields: Field[];
}

const Column = (props: Props): ReactElement => {
	const [itemOpen, setItemOpen] = useState(undefined);

	const handleItemOpen = (index: number) => {
		if (index === itemOpen) {
			setItemOpen(undefined);
			return;
		}
		setItemOpen(index);
	};

	return (
		<div className={classes.container}>
			{props.fields.map((item: Field, index: number) => {
				return (
					<Fragment key={index}>
						<div
							aria-haspopup="true"
							aria-expanded={itemOpen === index}
							aria-controls={`label_${index}`}
							role="button"
							tabIndex={0}
							className={classes.itemContainer}
							onClick={() => handleItemOpen(index)}
							onKeyDown={(
								e: React.KeyboardEvent<HTMLDivElement>
							) => {
								e.stopPropagation();
								if (e.keyCode === 13) {
									handleItemOpen(index);
								}
							}}
						>
							<div
								className={clsx(
									classes.button,
									itemOpen === index
										? classes.buttonSelected
										: null
								)}
							>
								{itemOpen === index ? "-" : "+"}
							</div>
							<div className={classes.itemLabel}>
								{RichText.asText(item.collapse_title)}
							</div>
						</div>
						<AnimateHeight
							duration={250}
							height={itemOpen === index ? "auto" : 0} // see props documentation below
						>
							<div
								className={classes.itemDescription}
								id={`label_${index}`}
							>
								{RichText.render(item.collapse_details)}
							</div>
						</AnimateHeight>
					</Fragment>
				);
			})}
		</div>
	);
};

export default Column;
