import { RichText } from "prismic-reactjs";
import React, { ReactElement } from "react";

import PrismicHref from "../../prismicHref";
import sliceClass from "../slices.module.css";
import styles from "./twoColumnImage.module.css";

interface Props {
	slice: any;
}

const TwoColumnImage = (props: Props): ReactElement => {
	const slice = props.slice.primary;
	return (
		<div className={sliceClass.container}>
			<div className={styles.container}>
				<div className={styles.column}>
					<div className={styles.imageContainer}>
						<img
							alt={slice.left_image.alt}
							src={slice.left_image.url}
							className={styles.image}
						/>
					</div>
					<div className={styles.textContainer}>
						{RichText.render(slice.left_image_title)}
					</div>
					<div className={styles.buttonContainer}>
						<PrismicHref
							link={slice.left_button_target}
							className={styles.button}
						>
							{RichText.asText(slice.left_button_title)}
						</PrismicHref>
					</div>
				</div>
				<div className={styles.column}>
					<div className={styles.imageContainer}>
						<img
							alt={slice.right_image.alt}
							src={slice.right_image.url}
							className={styles.image}
						/>
					</div>
					<div className={styles.textContainer}>
						{RichText.render(slice.right_image_title)}
					</div>
					<div className={styles.buttonContainer}>
						<PrismicHref
							link={slice.right_button_target}
							className={styles.button}
						>
							{RichText.asText(slice.right_button_title)}
						</PrismicHref>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TwoColumnImage;
