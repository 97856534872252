import { RichText } from "prismic-reactjs";
import React, { ReactElement } from "react";

import NewsletterContainer from "../../newsletter";
import classes from "./contactForm.module.css";

interface Props {
	slice: any;
}

const ContactForm = (props: Props): ReactElement => {
	const content = props.slice.primary;

	return (
		<div className={classes.columnContainer}>
			<div className={classes.columnA}>
				<div className={classes.section}>
					<div className={classes.header}>
						{RichText.asText(content.contact_form_label)}
					</div>
					<div className={classes.formContainer}>
						<form
							name="contact"
							method="post"
							data-netlify="true"
							data-netlify-honeypot="bot-field"
						>
							<input type="hidden" name="bot-field" />
							<input
								type="hidden"
								name="form-name"
								value="contact"
							/>
							<div className={classes.message}>
								Fields marked with an{" "}
								<span className={classes.required}>*</span> are
								required
							</div>
							<div className={classes.fieldContainer}>
								<label htmlFor="name" className={classes.label}>
									Name{" "}
									<span className={classes.required}>*</span>
								</label>
								<input
									className={classes.inputField}
									type="text"
									name="name"
									id="name"
									required
								/>
							</div>
							<div className={classes.fieldContainer}>
								<label
									htmlFor="email"
									className={classes.label}
								>
									Email{" "}
									<span className={classes.required}>*</span>
								</label>
								<input
									className={classes.inputField}
									required
									type="text"
									name="email"
									id="email"
								/>
							</div>
							<div className={classes.fieldContainer}>
								<label
									htmlFor="message"
									className={classes.label}
								>
									Message{" "}
									<span className={classes.required}>*</span>
								</label>
								<textarea
									required
									name="message"
									id="message"
									className={classes.textArea}
								/>
							</div>
							<div className={classes.fieldContainer}>
								<input
									required
									type="checkbox"
									name="agreeMessage"
									id="agreeMessage"
								/>
								<label
									htmlFor="agreeMessage"
									className={classes.checkLabel}
								>
									By using this form you agree with the
									storage and handling of your data by this
									website.
								</label>
							</div>
							<ul className={classes.actions}>
								<li>
									<input
										className={classes.button}
										type="submit"
										value="Send Message"
									/>
								</li>
								<li>
									<input
										type="reset"
										value="Clear"
										className={classes.button}
									/>
								</li>
							</ul>
						</form>
					</div>
				</div>
			</div>
			<div className={classes.columnB}>
				<div className={classes.section}>
					<div className={classes.header}>
						{RichText.asText(content.contact_details_label)}
					</div>
					<div className={classes.content}>
						{RichText.render(content.contact_details)}
					</div>
				</div>
				<div className={classes.section}>
					<NewsletterContainer />
				</div>
			</div>
		</div>
	);
};

export default ContactForm;
