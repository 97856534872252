import { RichText } from "prismic-reactjs";
import React, { ReactElement } from "react";

import EmailSVG from "../../../assets/email.svg";
import LinkedInSVG from "../../../assets/linkedin.svg";
import PrismicHref from "../../prismicHref";
import classes from "./employeeProfileList.module.css";

interface Props {
	slice: any;
}

const EmployeeProfileList = (props: Props): ReactElement => {
	return (
		<div className={classes.listContainer}>
			{props.slice.fields.map(({ employee }, index) => {
				return (
					<div key={index} className={classes.employeeContainer}>
						<div className={classes.imgContainer}>
							<PrismicHref
								link={employee}
								className={classes.employeeLinkContainer}
							>
								<img
									alt={`Profile photo of ${RichText.asText(
										employee.employee_name
									)}`}
									src={employee.employee_photo.url}
									className={classes.employeeImg}
								/>
							</PrismicHref>
						</div>
						<div className={classes.infoContainer}>
							<div className={classes.employee}>
								<div>
									{RichText.asText(employee.employee_name)}
								</div>
								<div>
									{RichText.asText(employee.employee_title)}
								</div>
							</div>
							<div className={classes.employeeSocial}>
								{employee.linked_in_url && (
									<PrismicHref
										ariaLabel="Open link to employee's LinkedIn"
										className={classes.button}
										newTab={true}
										link={employee.linked_in_url}
									>
										<LinkedInSVG
											className={classes.icon}
											alt="Linked In Icon"
										/>
									</PrismicHref>
								)}
								{employee.email && (
									<PrismicHref
										ariaLabel="Open link to employee's email"
										className={classes.button}
										newTab={true}
										link={employee.email}
									>
										<EmailSVG className={classes.icon} />
									</PrismicHref>
								)}
							</div>
						</div>
						<div className={classes.employeeBio}>
							{RichText.render(employee.short_bio)}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default EmployeeProfileList;
