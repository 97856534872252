import { RichText } from "prismic-reactjs";
import React, { ReactElement } from "react";

import sliceClasses from "../slices.module.css";
import classes from "./textContainer.module.css";

interface Props {
	slice: any;
}

const TextSlice = (props: Props): ReactElement => {
	return (
		<div className={sliceClasses.container}>
			<div className={classes.container}>
				{RichText.render(props.slice.primary.text)}
			</div>
		</div>
	);
};

export default TextSlice;
