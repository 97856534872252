import React, { ReactElement } from "react";

import classes from "./collapsibleList.module.css";
import Column from "./column";

interface Props {
	slice: any;
}

const CollapsibleList = (props: Props): ReactElement => {
	const fields = props.slice.fields;
	const halfOfArray = Math.floor(fields.length / 2);
	const arrayFirstHalf = fields.slice(0, halfOfArray);
	const arraySecondHalf = fields.slice(halfOfArray, fields.length);

	return (
		<div className={classes.container}>
			<Column fields={arrayFirstHalf} />
			<Column fields={arraySecondHalf} />
		</div>
	);
};

export default CollapsibleList;
