import { RichText } from "prismic-reactjs";
import React, { ReactElement } from "react";

import styles from "./horizontalLine.module.css";

interface Props {
	slice: {
		primary: {
			optional_line_text?: string;
		};
	};
}

const HorizontalLine = (props: Props): ReactElement => {
	return (
		<div className={styles.container}>
			<div className={styles.content}>
				{RichText.asText(props.slice.primary.optional_line_text)}
			</div>
			<div className={styles.contentSeperator} />
		</div>
	);
};

export default HorizontalLine;
