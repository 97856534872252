import React, { ReactElement } from "react";

import classes from "./skinnyHeader.module.css";

interface Props {
	imageUrl: string;
	label?: string;
}

const SkinnyHeader = (props: Props): ReactElement => {
	return (
		<div
			className={classes.headerContainer}
			style={{
				backgroundImage: `linear-gradient(rgba(3, 4, 17, 0.6), rgba(3, 4, 17, 0.6)), url(${props.imageUrl})`,
			}}
		>
			<div className={classes.container}>
				<div className={classes.labelContainer}>{props.label}</div>
			</div>
		</div>
	);
};

export default SkinnyHeader;
