import React, { Fragment, ReactElement } from "react";

import CollapsibleList from "./collapsibleList";
import ContactForm from "./contactForm/contactForm";
import EmployeeProfileList from "./employeeProfileList";
import FullImage from "./fullImage";
import HorizontalLine from "./horizontalLine";
import InfoCardGroup from "./infoCardGroup";
import LogoGroup from "./logoGroup";
import SkinnyImage from "./skinnyImage/skinnyImage";
import classes from "./slices.module.css";
import TextContainer from "./textContainer";
import TwoColumnImage from "./twoColumnImage";

interface Slice {
	type: string;
}

interface Props {
	slices: any[];
}

const Slices = (props: Props): ReactElement => {
	return (
		<div>
			{props.slices.map((slice, index): any => {
				switch (slice.type) {
					case "splash_image":
						return <TwoColumnImage slice={slice} key={index} />;
					case "horizontal_line":
						return (
							<div className={classes.container} key={index}>
								<HorizontalLine slice={slice} />
							</div>
						);
					case "logo_group":
						return <LogoGroup slice={slice} key={index} />;
					case "text":
						return <TextContainer slice={slice} key={index} />;
					case "full_image":
						return <FullImage slice={slice} key={index} />;
					case "employee_profile_list":
						return (
							<div className={classes.container} key={index}>
								<EmployeeProfileList slice={slice} />
							</div>
						);
					case "collapsible_list":
						return (
							<div className={classes.container} key={index}>
								<CollapsibleList slice={slice} />
							</div>
						);
					case "info_card_group":
						return (
							<div className={classes.container} key={index}>
								<InfoCardGroup slice={slice} />
							</div>
						);
					case "skinny_image":
						return <SkinnyImage slice={slice} key={index} />;
					case "contact_form":
						return (
							<div className={classes.container} key={index}>
								<ContactForm slice={slice} />
							</div>
						);
					default:
						console.log(slice.type);
						return;
				}
			})}
		</div>
	);
};

export default Slices;
