import React, { ReactElement } from "react";

import classes from "./fullImage.module.css";

interface Props {
	slice: any;
}

const FullImage = (props: Props): ReactElement => {
	const image = props.slice.primary.full_image.url;
	return (
		<div
			className={classes.masthead}
			style={{ backgroundImage: `url(${image})` }}
		/>
	);
};

export default FullImage;
