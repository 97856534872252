import { RichText } from "prismic-reactjs";
import React, { ReactElement } from "react";

import classes from "./infoCardGroup.module.css";

interface Props {
	slice: any;
}

const InfoCardGroup = (props: Props): ReactElement => {
	const { fields } = props.slice;
	return (
		<>
			<div className={classes.container}>
				{fields.map((field, index) => {
					return (
						<div className={classes.viewContainer} key={index}>
							<div className={classes.viewImage}>
								<div className={classes.avatarContainer}>
									<img
										alt={field.imageIcon.alt}
										src={field.imageIcon.url}
										className={classes.avatar}
									/>
								</div>
							</div>
							<div className={classes.text}>
								{RichText.render(field.card_title)}
								{RichText.render(field.card_details)}
							</div>
						</div>
					);
				})}
			</div>
		</>
	);
};

export default InfoCardGroup;
